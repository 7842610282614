import React from 'react';

import {
    sectionBox,
    grid,
    container,
    titleText,
    separator,
    contentText,
    content,
    formBox,
    form,
    notReceivedText,
    sendCodeButton,
    success,
} from './candidate-verification.module.scss';
import { ISection } from '../../models/section.model';
import { FormikConfig } from 'formik';
import useTranslations from '../../hooks/use-translations';
import {
    candidateVerificationForm,
    TCandidateVerificationValues,
} from '../../formik/candidate-verification.form';
import { useCandidate } from '../../hooks/use-candidate';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useModal } from '../../hooks/use-modal';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import Button from '../atoms/button';

export interface ICandidateVerificationProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

const CandidateVerification: React.FC<ICandidateVerificationProps> = ({
    section,
    className,
    TitleTag = 'h2',
}) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('CandidateVerification');
    const candidate = useCandidate({ dontSkip: true });
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(candidate.verify.errors);
    const { addModal } = useModal();

    const handleSubmit: FormikConfig<TCandidateVerificationValues>['onSubmit'] = (values) => {
        candidate.verify.fetch(values);
    };

    const handleSendCode = () => {
        addModal({
            modalKey: 'candidate-verification-modal',
        });
    };

    const handleNext = async () => {
        // after positive verification candidate status changes
        // updating status with candidate refetch will trigger redirect in candidate template
        candidate.get.refetch();
    };

    return (
        <Section
            className={`${className} ${sectionBox} ${candidate.verify.isSuccess ? success : ''}`}
            classes={{ container: grid }}
            style={style}
            css={css}
            sectionId={sectionId}
            roundedSection={true}
        >
            <div className={container}>
                <div className={content}>
                    <TitleTag className={titleText}>
                        {candidate.verify.isSuccess ? t.titleSuccess : t.title}
                    </TitleTag>
                    <span className={separator} />
                    <p className={contentText}>
                        {candidate.verify.isSuccess ? t.contentSuccess : t.content}
                    </p>
                </div>
                {!candidate.verify.isSuccess && (
                    <FormGenerator
                        formRef={formikRef}
                        className={formBox}
                        formClassName={form}
                        formikProps={{ onSubmit: handleSubmit }}
                        name="CandidateVerification"
                        fields={candidateVerificationForm}
                        errorMessage={globalErrorMessage}
                        submitLoading={candidate.verify.isLoading}
                    >
                        <p className={notReceivedText}>
                            {t.codeNotReceived}{' '}
                            <button
                                type="button"
                                className={sendCodeButton}
                                onClick={handleSendCode}
                            >
                                {t.sendCodeButton}
                            </button>
                        </p>
                    </FormGenerator>
                )}
                {candidate.verify.isSuccess && (
                    <Button onClick={handleNext} isLoading={candidate.get.isFetching}>
                        {t.nextButton}
                    </Button>
                )}
            </div>
        </Section>
    );
};

export default CandidateVerification;
