// extracted by mini-css-extract-plugin
export var backgroundAnimation = "candidate-verification-module--backgroundAnimation--65b2d";
export var container = "candidate-verification-module--container--03b56";
export var content = "candidate-verification-module--content--f839f";
export var contentText = "candidate-verification-module--content-text--6f424";
export var form = "candidate-verification-module--form--6cc8d";
export var formBox = "candidate-verification-module--form-box--bbfe1";
export var grid = "candidate-verification-module--grid--4eb90";
export var notReceivedText = "candidate-verification-module--not-received-text--d569f";
export var sectionBox = "candidate-verification-module--section-box--76da9";
export var sendCodeButton = "candidate-verification-module--send-code-button--b8ed4";
export var separator = "candidate-verification-module--separator--c59fa";
export var success = "candidate-verification-module--success--99589";
export var titleText = "candidate-verification-module--title-text--95ed9";