import * as Yup from 'yup';

import { FormGeneratorInitialValues, TFormFieldsFactoryProps } from '../models/form-field.model';

export const candidateVerificationForm = ({
    t,
}: TFormFieldsFactoryProps<'CandidateVerification'>) =>
    [
        {
            name: 'code',
            type: 'text',
            label: t.code.label,
            initialValue: '',
            placeholder: t.code.placeholder,
            schema: Yup.string().required(t.code.error.required),
        },
        {
            name: 'submit',
            type: 'submit',
            label: t.submit.label,
        },
    ] as const;

export type TCandidateVerificationValues = FormGeneratorInitialValues<
    ReturnType<typeof candidateVerificationForm>,
    string
>;
